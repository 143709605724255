import React from "react";
import { SignUpHeader } from "../styled-components/SignUpHeader";
import { useNavigate } from "react-router-dom";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { SubsidiaryNav } from "../styled-components/SubsidiaryNav";

const EYEBROW_TEXT = "Step 2 of 3";
const HEADER_TEXT = "Choose a time for your call below";
const SUB_HEADER_TEXT = [
  "Please note that we are only able to accept applicants from USA, Canada, UK, Europe, Australia, New Zealand, Japan and the Middle East at this time.",
  <div className="mt-[12px]" />,
  "Due to the high volume of applications that we receive, we cannot offer reschedules. So if you do not show up for the call you will NOT be able book another.",
  <div className="mt-[12px]" />,
  "If you cannot make the time, please do not book the call."
];

export function Schedule() {
  const navigate = useNavigate();

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      navigate("/join");
    }
  });

  return (
    <div className="title-background w-full h-lvh flex flex-col items-center text-white font-jakarta whitespace-pre-line">
      <SubsidiaryNav />
      <div className="flex flex-col max-w-[820px] h-full p-6 border-dashed border-x border-x-[#80808070]">
        <SignUpHeader
          eyebrowText={EYEBROW_TEXT}
          headerText={HEADER_TEXT}
          subHeaderText={SUB_HEADER_TEXT}
        />
        <InlineWidget
          styles={{
            maxWidth: "100%",
            width: "100%",
            height: "800px",
            margin: 0,
            padding: 0
          }}
          pageSettings={{ hideGdprBanner: true }}
          url="https://calendly.com/d/467-3mk-xcz?hide_gdpr_banner=1&utm_source=newwebsite_newcalendly" 
        />
      </div>
    </div>
  );
}
