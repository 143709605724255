export const COACH_INFO = [
  {
    name: "Konpeki",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/coaches/profile-photos/konpeki.png`,
    tags: ["Initiator", "Duelist", "Top 50 Radiant"],
    rankAsset: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
  },
  {
    name: "Genghsta",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/coaches/profile-photos/genghsta.png`,
    tags: ["Initiator", "Controller", "Former IMT player", "DSG", "Knights"],
    rankAsset: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
  },
  {
    name: "Milan",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/coaches/profile-photos/milan.png`,
    tags: ["Initiator", "Duelist", "IGL", "VCT 2021 Winner Analyst"],
    rankAsset: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
  },
  {
    name: "ScrewFace",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/coaches/profile-photos/screwface.png`,
    tags: ["Duelist", "Initiator", "Controller", "2023 Champion Winner on EG"],
    rankAsset: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
  },
  {
    name: "evaD",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/coaches/profile-photos/evad.png`,
    tags: ["Controller", "Initiator", "Top 32 VCT Qualifiers"],
    rankAsset: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
  },
  {
    name: "Starriebuns",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/coaches/profile-photos/starriebuns.png`,
    tags: [
      "Controller",
      "Inititator",
      "In-game leader",
      "GC Pro (XSET Purple)"
    ],
    rankAsset: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
  },
  {
    name: "Randomnoob",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/coaches/profile-photos/randomnoob.png`,
    tags: ["Controller", "In-game leader", "Tier 2 NA Pro"],
    rankAsset: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
  },
  {
    name: "Maru",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/coaches/profile-photos/maru.png`,
    tags: ["Sentinel", "Initiator", "Tier 1 EU Pro (XL Esports)"],
    rankAsset: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
  },
  {
    name: "Comet",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/coaches/profile-photos/comet.png`,
    tags: ["Controller", "IGL", "Tier 1 EU Analyst (Acend)"],
    rankAsset: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
  },
  {
    name: "Tiraye",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/coaches/profile-photos/tiraye.png`,
    tags: ["Controller", "Sentinel", "GC Pro (Gen.G Black)"],
    rankAsset: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
  },
  {
    name: "Powa",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/coaches/profile-photos/powa.png`,
    tags: ["Duelist", "Initiator", "Top 100 Radiant"],
    rankAsset: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
  },
  {
    name: "Thwifo",
    photoUrl: `${process.env.REACT_APP_ASSET_URL}/coaches/profile-photos/thwifo.png`,
    tags: ["Initiator", "Sentinel", "Duelist", "XSET", "T1", "NRG", "100T"],
    rankAsset: `${process.env.REACT_APP_ASSET_URL}/ranks/radiant.png`
  }
];
